<template>
  <div>
    <div style="line-height: 10px">
      <h4>Statistics</h4>
      <span class="text-muted">Your business growth</span>
    </div>
    <b-row class="mt-2">
      <b-col md="3">
        <b-card style="border-radius: 1.5rem">
          <b-card-title class="text-muted" style="font-size: 14px">
            <feather-icon icon="UserIcon" size="18"></feather-icon>
            Employees
          </b-card-title>
          <div class="d-flex justify-content-center align-items-center">
            <h1 style="margin-top: 10px" class="bold">
              {{ allEmployees.length }}
            </h1>
            <!-- <div class="rounded-circle text-primary" style="background: #ece9ff; padding: 5px 10px">
              <feather-icon icon="PlusIcon" size="14"></feather-icon>
            </div> -->
          </div>
          <b-avatar-group size="32px">
            <b-avatar
              v-for="photo in allEmployeesPictures"
              :key="photo.id"
              :src="photo.profile_photo_path"
              v-b-tooltip.hover
              :title="photo.name"
              class="mt-2 pull-up"
            />
            <b-avatar variant="primary mt-2 pull-up"
              ><span>More</span></b-avatar
            >
          </b-avatar-group>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card style="border-radius: 1.5rem">
          <b-card-title class="text-muted" style="font-size: 14px">
            <feather-icon icon="UserPlusIcon" size="18"></feather-icon>
            New employee in the month
          </b-card-title>
          <div class="d-flex justify-content-center align-items-center">
            <h1 style="margin-top: 10px" class="bold">
              {{ currentEmployeesMonth.length }}
            </h1>
            <!-- <div class="rounded-circle text-primary" style="background: #ece9ff; padding: 5px 10px">
              <feather-icon icon="PlusIcon" size="14"></feather-icon>
            </div> -->
          </div>
          <b-avatar-group size="32px">
            <b-avatar
              v-for="photo in currentEmployeesMonthPictures"
              :key="photo.id"
              :src="photo.profile_photo_path"
              v-b-tooltip.hover
              :title="photo.name"
              class="mt-2 pull-up"
            />
          </b-avatar-group>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card style="border-radius: 1.5rem">
          <b-card-title class="text-muted" style="font-size: 14px">
            <feather-icon icon="UserMinusIcon" size="18"></feather-icon>
            Overdue contract
          </b-card-title>
          <div class="d-flex justify-content-center align-items-center">
            <h1 style="margin-top: 10px" class="bold">0</h1>
            <!-- <div class="rounded-circle text-primary" style="background: #ece9ff; padding: 5px 10px">
              <feather-icon icon="PlusIcon" size="14"></feather-icon>
            </div> -->
          </div>
          <b-avatar src="" alt="profile" class="mt-2" />
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card style="border-radius: 1.5rem">
          <b-card-title class="text-muted" style="font-size: 14px">
            <feather-icon icon="UserCheckIcon" size="18"></feather-icon>
            Contract to expired
          </b-card-title>
          <div class="d-flex justify-content-center align-items-center">
            <h1 style="margin-top: 10px" class="bold">0</h1>
            <!-- <div class="rounded-circle text-primary" style="background: #ece9ff; padding: 5px 10px">
              <feather-icon icon="PlusIcon" size="14"></feather-icon>
            </div> -->
          </div>
          <b-avatar src="" alt="profile" class="mt-2" />
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <b-row align="center" class="justify-content-center">
        <b-col md="3" class="mr-5">
          <h5 class="mb-1">Employee Chart by Age</h5>
          <Chart :width="chartWidth" type="doughnut" :data="chartData" />
        </b-col>
        <b-col md="3" class="ml-5">
          <h5 class="mb-1">Contract Type Chart</h5>
          <Chart :width="chartWidth" type="doughnut" :data="chartData" />
        </b-col>
      </b-row>
    </b-card>

    <b-card class="mt-2" title="Expired contract that needed to be expired">
      <div class="d-flex justify-content-between align-items-center mb-1">
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <label for="" class="mr-1" style="font-size: 14px">Sort</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-100"
            />
          </div>
          <download-excel
            :data="exportItems"
            :fields="exportFields"
            :filename="'Employee'"
            :sheetname="'Employee'"
          >
            <b-button
              variant="outline-secondary"
              size="sm"
              style="padding: 0.35rem"
              class="ml-1"
            >
              <feather-icon icon="FileIcon" size="20" />
              <label style="font-size: 14px; margin-left: 5px">Export</label>
            </b-button>
          </download-excel>
        </div>
        <div class="d-flex align-items-center">
          <label for="" class="mr-1" style="font-size: 14px">Search</label>
          <b-form-input
            id="searchInput"
            v-model="filter"
            size="sm"
            type="search"
            placeholder="Search"
          />
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="itemsNearlyExpired"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template #cell(name)="data">
          <span
            v-b-modal="'detail_employee_' + data.value"
            class="text-primary"
          >
            {{ data.value }}
          </span>
        </template>
        <template #cell(profile_photo_url)="data">
          <b-avatar :src="data.value" />
        </template>

        <template #cell(is_active)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>

      <div class="d-flex justify-content-between align-items-center">
        <span>
          Showing
          {{ perPage > totalRows ? totalRows : perPage }} of
          {{ totalRows }} entries
        </span>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </div>
    </b-card>

    <b-card class="mt-2" title="List of nearly expired contracts">
      <div class="d-flex justify-content-between align-items-center mb-1">
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <label for="" class="mr-1" style="font-size: 14px">Sort</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-100"
            />
          </div>
          <download-excel
            :data="exportItems"
            :fields="exportFields"
            :filename="'Employee'"
            :sheetname="'Employee'"
          >
            <b-button
              variant="outline-secondary"
              size="sm"
              style="padding: 0.35rem"
              class="ml-1"
            >
              <feather-icon icon="FileIcon" size="20" />
              <label style="font-size: 14px; margin-left: 5px">Export</label>
            </b-button>
          </download-excel>
        </div>
        <div class="d-flex align-items-center">
          <label for="" class="mr-1" style="font-size: 14px">Search</label>
          <b-form-input
            id="searchInput"
            v-model="filter"
            size="sm"
            type="search"
            placeholder="Search"
          />
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="itemsExpired"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template #cell(name)="data">
          <span
            v-b-modal="'detail_employee_' + data.value"
            class="text-primary"
          >
            {{ data.value }}
          </span>
        </template>
        <template #cell(profile_photo_url)="data">
          <b-avatar :src="data.value" />
        </template>

        <template #cell(is_active)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>

      <div class="d-flex justify-content-between align-items-center">
        <span>
          Showing
          {{ perPage > totalRows ? totalRows : perPage }} of
          {{ totalRows }} entries
        </span>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </div>
    </b-card>
  </div>
</template>

<style>
.btn-group-sm > .btn {
  padding: 0.3rem 1rem;
}

.btn-outline-secondary {
  border: 1px solid #d8d6de !important;
}
</style>

<script>
import VBTooltip from "bootstrap-vue";
import moment from "moment";
import Chart from "primevue/chart";

export default {
  components: {
    Chart,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      chartWidth: 100,

      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      },

      // table
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      fields: [
        {
          key: "hrcode",
          label: "HR Code",
        },
        {
          key: "profile_photo_url",
          label: "Avatar",
        },
        { key: "name", label: "Full Name", sortable: true },
        "email",
        { key: "department.name", label: "Department", sortable: "true" },
        { key: "team.name", label: "Position", sortable: "true" },
        { key: "is_active", label: "Status", sortable: true },
      ],
      items: [],
      itemsNearlyExpired: [],
      itemsExpired: [],
      status: [
        {
          1: "Active",
          2: "Inactive",
        },
        {
          1: "light-success",
          2: "light-danger",
        },
      ],

      // export excel
      exportItems: [],
      exportFields: {
        "HR Code": "hrcode",
        Name: "name",
        Email: "email",
        Department: "department.name",
        Position: "team.name",
        Status: "is_active",
      },

      allEmployees: [],
      allEmployeesPictures: [],
      currentEmployeesMonth: [],
      currentEmployeesMonthPictures: [],
    };
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.totalRows = this.items.length;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.callApi({
        method: "GET",
        url: "/users/getAll",
        success: (response) => {
          response.result.data.map((item) => {
            this.allEmployees.push(item);
            this.chartData.labels.push(item.age);
          });
          this.chartData.labels = [...new Set(this.chartData.labels)];
          this.chartData.labels.map((item) => {
            let count = 0;
            response.result.map((item2) => {
              if (item == item2.age) {
                count++;
              }
            });
            this.chartData.datasets[0].data.push(count);
            this.chartData.datasets[0].backgroundColor.push(
              "#" + Math.floor(Math.random() * 16777215).toString(16)
            );
          });

          this.allEmployeesPictures = this.allEmployees.slice(5, 10);
          this.currentEmployeesMonth = this.allEmployees.filter((item) => {
            return (
              moment(item.created_at).format("MM") == moment().format("MM")
            );
          });
          if (this.currentEmployeesMonth.length > 5) {
            this.currentEmployeesMonthPictures =
              this.currentEmployeesMonth.slice(0, 5);
          } else {
            this.currentEmployeesMonthPictures = this.currentEmployeesMonth;
          }

          this.totalRows = this.items.length;
          this.items = response.result.data;
          this.exportItems = response.result;
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Data not Found",
              variant: "error",
            },
          });
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
